import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Footer() {
    const[year, setYear] = useState(new Date().getFullYear());
    return (
       <>
       <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>About The Store</h3>

                            <div className="about-the-store">
                                <p>One of the most popular on the web is shopping.</p>
                                <ul className="footer-contact-info">
                                    <li><i className='bx bx-map'></i> <a href="#" target="_blank">6-b Ogunlana Lane, Off Ogunlana Drive at Marsha, Surulere, Lagos</a></li>
                                    <li><i className='bx bx-phone-call'></i> <a href="tel:+23407045976541"> +234 (0) 704 597 6541</a></li>
                                    <li><i className='bx bx-phone-call'></i><a href="tel:+2348167402780">+234 (0) 816 740 2780</a></li>
                                    <li><i className='bx bx-phone-call'></i> WhatsApp: <a href="tel:+23407045976541">+234 (0) 704 597 6541</a></li>
                                    <li><i className='bx bx-envelope'></i> <a href="mailto:sales@cobbmed.com">sales@cobbmed.com</a></li>
                                </ul>
                            </div>

                            <ul className="social-link">
                                <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-facebook'></i></a></li>
                                <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-twitter'></i></a></li>
                                <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-instagram'></i></a></li>
                                <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-pinterest-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget pl-4">
                            <h3>Quick Links</h3>
                            <ul className="quick-links">
                                <li><Link to="/about#">About Us</Link></li>
                                <li><Link to="/shops">Shop Now!</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Customer Support</h3>
                            <ul className="customer-support">
                                <li><Link to="/account">My Account</Link></li>
                                <li><Link to="/checkout">Checkout</Link></li>
                                <li><Link to="/cart">Cart</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Newsletter</h3>
                            <div className="footer-newsletter-box">
                                <p>To get the latest news and latest updates from us.</p>
                                <form className="newsletter-form" data-bs-toggle="validator">
                                    <label>Your E-mail Address:</label>
                                    <input type="email" className="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off"/>
                                    <button type="submit">Subscribe</button>
                                    <div id="validator-newsletter" className="form-result"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright 2020 - {year} Cobb Medical Supplies Ventures. All rights reserved.</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul className="payment-types">
                                <li><a href="#" target="_blank"><img src="assets/img/payment/visa.png" alt="image"/></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/payment/mastercard.png" alt="image"/></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/payment/mastercard2.png" alt="image"/></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/payment/visa2.png" alt="image"/></a></li>
                                <li><a href="#" target="_blank"><img src="assets/img/payment/expresscard.png" alt="image"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </footer>

        <div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
       </>
    );
    
}

export default Footer;