import React, { useEffect, useState } from 'react';
import { appendScript } from '../../utils/appendScript'
import SiteMap from '../../components/header/site-map';
import currency from '../../utils/currency.format';
import formatDate from '../../utils/date.format';
import { useAuth } from '../../context/user/user.context';
import Grid from '../../components/loader/grid';
import { $http } from '../../instance/axios.instance';


const Account = () => {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const { user } = useAuth();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true)
                const response = await $http.get(`/Order/byCustomer/${user.id}/0/30`, {
                    headers: {
                      'Authorization': `bearer ${localStorage.getItem("token")}`
                    }
                  });
                setOrders(response.data);
                setLoading(false)
            }
            catch (e) {
                console.log(e)
                setLoading(false)
            }
        };

        fetchData();
        document.title = "Cobbmed : Orders";
        appendScript("assets/js/main.js");
    }, []);


    return (
        <>
            <SiteMap name={"Orders"} productName={user.displayName} />
            <section className="cart-area ptb-100">

                <div className="container">
                    <div className="cart-table table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Total Item</th>
                                    <th scope="col">Total Bill</th>
                                </tr>
                            </thead>

                            <tbody>
                                {!loading ?
                                    orders &&
                                    orders.map(item =>
                                        <tr key={item.id}>
                                            <td className="product-price">
                                                {item.invoice_number}
                                            </td>
                                            <td className="product-price">
                                                {formatDate(item.date)}
                                            </td>
                                            <td className="product-name">
                                                {item.description}

                                            </td>
                                            <td className="product-quantity">
                                                {item.total_items}
                                            </td>
                                            <td className="product-price">
                                                <span className="unit-amount">{currency(item.total_bill)}</span>
                                            </td>


                                        </tr>
                                    )
                                    :
                                    <Grid />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )

}
export default Account;