import React, { lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import { ProtectRoutes } from '../hooks/protectRoutes/index';
import Layout from '../components/layout/layout';
import LayoutWithFacility from '../components/layout/layout2';
import About from '../pages/about';
import Contact from '../pages/contact';
import FAQ from '../pages/faq';
import HomePage from '../pages/homepage/Index';
import Login from '../pages/login';
import SignUp from '../pages/signup';
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import Shop from "../pages/product/product";
import { CartProvider } from '../context/cart/cart.context';
import ProductDetails from '../pages/product/product-details';
import Account from '../pages/auth';
import Grid from '../components/loader/grid';
import Success from '../pages/success';

const Pages = () => {
    return (
        <Suspense fallback={<Grid />}>
            <CartProvider>
                <Routes>
                    <Route element={<LayoutWithFacility />}>
                        <Route path="/" element={<HomePage />} />                        
                    </Route>
                    <Route element={<LayoutWithFacility />}>                    
                        <Route path="/shops" element={<Shop />} />
                        <Route path="/product-details/:id" element={<ProductDetails />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/checkout" element={<Checkout />} />

                        <Route path="/contact" element={<Contact />} />

                        <Route path="/faqs" element={<FAQ />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />

                        <Route element={<ProtectRoutes />}>
                            <Route path='/account' element={<Account />} />
                            <Route path='/success' element={<Success />} />
                        </Route>
                    </Route>
                </Routes>
            </CartProvider>

        </Suspense>
    );
}

export default Pages;