
import React, { useContext, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { reducer, initialState } from "../cartReducer"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { $http } from "../../instance/axios.instance";
import { Config } from "../../config";

export const CartContext = React.createContext({
  state: initialState,
  dispatch: () => null
})

export const CartProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const navigate = useNavigate();

  const removeCart = (itemid) => {
    updateCart(state.carts.filter(x => x.id !== itemid))
    toast.success('Item removed from cart', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });
  };

  const updateCart = (items) => {
    dispatch({ type: 'SET_CART', carts: items });
  };
  const clearCart = () => {
    localStorage.removeItem("items");
  };

  const addCart = (item) => {
    // var cart = carts.find(x => {
    //   return x.id === item.id
    // })
    carts.push(item)
    // if (cart) {
    //   cart.qty = item.qty;
    //   cart.size = item.size;
    // }
    // else {
      
    // }
    updateCart(carts);

    toast.success('Item added to cart', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });

  };

  const getTotal = () => {
    let sum = 0;
    carts.forEach(x => {
      sum += (x.price * x.qty)
    });
    return sum;
  }

  const genReference = () =>{
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    
    return text + (new Date()).getTime().toString();
  }

  const ProcessOrder = (reference, paystackRef, note, user) =>{

    var Order = {}
    Order.patient_id = user.id;
    Order.created_by = user.id;
    Order.owner_id = user.owner_id;
    Order.location_id = user.location_id;
    Order.total_bill = total;
    Order.sales_channel = 0;
    Order.platform = "browser";
    Order.delivery_location_id = Config.delivery_location;
    Order.delivery_address_id = Config.delivery_location;
    Order.delivery_fee = Config.delivery_fee;
    Order.order_ref = reference
    Order.preorder_id = null;
    Order.comment = note;

    Order.items = carts.map((item) => {
      return {
        item_id: item.id,
        unit_price: item.price,
        quantity_purchased: parseInt(item.qty),
        size_designation_id: item.size?.id
      }
    })

    $http.post(`/order/final`, { order: Order, transaction_ref: paystackRef,payment_merchant: 2 }, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem("token")}`
      }
    }).
      then((res) => {
        if (res.status === 200) {
          if (res.data.id !== undefined) {
            return navigate("/success")
          }
        }
        else {
          alert("There was a problem validating your payment. Please try again")
          //return navigate("/payment/failure")
        }
      }).catch((ex) => {
        //commit("appState/SET_LOADING_STATE", false, { root: true })
        console.log(ex)
      })

  }

  const carts = state.carts;
  const total = getTotal();
  const count = state.carts.length;

  const value = useMemo(
    () => ({
      removeCart,
      updateCart,
      addCart,
      carts,
      total,
      count: state.carts.length,
      ProcessOrder,
      genReference,
      clearCart
    }),
    [state]
  );

  return (
    <CartContext.Provider value={value}>
      {children}
      <ToastContainer />
    </CartContext.Provider>
  )
}

export const useCart = () => {
  return useContext(CartContext)
};