import React from 'react';
import SiteMap from '.././components/header/site-map';

const Contact = () => {
    return (
        <>
        <SiteMap name={"Contact Us"} pageName={"Contact"} />
            <section className="contact-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="contact-info">
                                <h3>Here to Help</h3>
                                <p>Have a question? You may find an answer in our <a href="#">FAQs</a>. But you can also contact us.</p>

                                <ul className="contact-list">
                                    <li><i className='bx bx-map'></i> Location: <a href="#">6-b Ogunlana Lane, Off Ogunlana Drive at Marsha, Surulere, Lagos</a></li>
                                    <li><i className='bx bx-phone-call'></i> Call Us: <a href="tel:+23407045976541">+234 (0) 704 597 6541</a></li>
                                    <li><i className='bx bx-phone-call'></i> Call Us: <a href="tel:+2348167402780">+234 (0) 816 740 2780</a></li>
                                    <li><i className='bx bx-phone-call'></i> WhatsApp: <a href="tel:+23407045976541">+234 (0) 704 597 6541</a></li>
                                    <li><i className='bx bx-envelope'></i> Email Us: <a href="mailto:sales@cobbmed.com">sales@cobbmed.com</a></li>
                                </ul>

                                <h3>Opening Hours:</h3>
                                <ul className="opening-hours">
                                    <li><span>Monday - Friday :</span> 10AM - 5PM</li>
                                </ul>

                                <h3>Follow Us:</h3>
                                <ul className="social">
                                    <li><a href="#" target="_blank"><i className='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-linkedin'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-skype'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-pinterest-alt'></i></a></li>
                                    <li><a href="#" target="_blank"><i className='bx bxl-youtube'></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div className="contact-form">
                  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2987.7593473566985!2d-73.78797548432667!3d41.509489596379204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd490255c9bfa7%3A0xfe099945f43b6e47!2sWonderland%20Dr%2C%20East%20Fishkill%2C%20NY%2012533%2C%20USA!5e0!3m2!1sen!2sbd!4v1622957216342!5m2!1sen!2sbd"></iframe>
            </div>
        </>
    );
}
export default Contact;