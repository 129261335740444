export const Config = {
    owner_id:"5de785521c9d440000aaf4a3",
    key: "pk_live_d8ee83dcbeb8958382f9ee119a3d3e20f98ff4a3",
    delivery_location: "60b13d3a5332d02b83b8cfdb",
    delivery_fee: 2500
}
// export const Config = {
//     owner_id:"6010433ad1c6b91bf8e6cf60",
//     key: "pk_test_373dde116f4055e997e6692e0f42200df003443a",
//     delivery_location: "60b13d185332d02b83b8cfd6",
//     delivery_fee: 2500
// }