
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from '../../context/user/user.context';

export const ProtectRoutes = () => {
    const location = useLocation();
    const redirectLoginUrl = `/login?redirectTo=${encodeURI(location.pathname)}`;

    const { user } = useAuth();

    return user ? <Outlet /> : <Navigate to={redirectLoginUrl} exact />
};