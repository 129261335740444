import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './menu';
import TopHeader from "./first-top-header";

const header = () =>{
    return (
        <>
            <TopHeader />
            {/* Start Navbar Area */}
            <div className="navbar-area">
                <div className="xton-responsive-nav">
                    <div className="container">
                        <div className="xton-responsive-menu">
                            <div className="logo">
                                <Link to="/">
                                    <img src="assets/img/logo.png" className="main-logo" alt="logo" />
                                    <img src="assets/img/white-logo.png" className="white-logo" alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="xton-nav">
                    <div className="container">
                        <Menu/>
                    </div>
                </div>
            </div>
            {/* End Navbar Area */}

            {/* Start Sticky Navbar Area */}
            <div className="navbar-area header-sticky">
                <div className="xton-nav">
                    <div className="container">
                        <Menu />
                    </div>
                </div>
            </div>
            {/* End Header Area */}

            {/* Start Search Overlay */}
            <div className="search-overlay">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>

                        <div className="search-overlay-close">
                            <span className="search-overlay-close-line"></span>
                            <span className="search-overlay-close-line"></span>
                        </div>

                        <div className="search-overlay-form">
                            <form>
                                <input type="text" className="input-search" placeholder="Search here..." />
                                <button type="submit"><i className='bx bx-search-alt'></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Search Overlay  */}
        </>
    );
}
export default header