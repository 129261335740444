import React from "react"
import { Outlet } from "react-router-dom";
import { UserProvider } from "../../context/user/user.context";
import Header from "../header/header"
import Footer from "../footer/footer"
import SideBarModal from '../modal/sideBarModal'
import Instagram from "../social/instagram";
import Features from "../others/features";

class Layout extends React.Component {
  render() {
    return (
      <>
        <UserProvider>
          <Header />
          <Outlet />
          <Features/>
          {/* <Instagram/> */}
          <SideBarModal />
          <Footer />
        </UserProvider>
      </>
    )
  }
}
export default Layout;