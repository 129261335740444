import React from 'react';
import { Link } from 'react-router-dom';

function useHeroHeader({name, pageName}){
  return(
    <>
      <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{name}</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li>{pageName}</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  );
};

export default useHeroHeader;