import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useCart } from "../../context/cart/cart.context";
import { appendScript } from "../../utils/appendScript";
import SiteMap from "../../components/header/site-map";
import { $http } from "../../instance/axios.instance";
import { useAuth } from "../../context/user/user.context";
import { ToastContainer, toast } from 'react-toastify';

import clsx from "clsx";

function ProductDetails() {
  const [isOpen, setIsOpen] = useState(false);
  const { authenticate } = useAuth();
  let params = useParams();
  const id = params.id;
  const [product, setProduct] = useState({ images: [] });
  const [size, setItemSize] = useState({ });
  const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState(1);
  const { addCart } = useCart();

  async function Auth() {
    await authenticate();
  }

  const reload = false;
  useEffect(() => {
    appendScript("assets/js/main.js");

    async function fetchData() {
      try {
        setLoading(true);
        const response = await $http.get(`Item/${id}`);
        setProduct(response.data);
        setLoading(false);
        document.title = `Cobbmed : ${response.data.item}`;
      } catch (e) {
        if (e.response.status === 401) {
          await Auth();
          //await loadPage();
        }
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  function addToCart() {
    if(product.size_designation_items?.length > 0 && product.size == undefined){
      toast.error('Please select your preferred product size', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
      return;
    }
    addCart({
      id: product.id,
      qty: qty,
      name: product.name,
      price: product.price,
      image: product.image,
      size: product.size
    });
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  function setSize(size) {
    if(size.quantity_available <=0)
    {
      toast.error('The selected size is currently not available', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
      return;
    }
    product.size = size;
    setProduct(product);
    setItemSize(size)
  }

  const ref = (useRef < HTMLDivElement) | (null > null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen && setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setIsOpen]);

  return (
    <>
      <SiteMap name={product.item} pageName={"Product Details"} />
      <section className="product-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              {product.images.length > 0 && (
                <div className="products-details-image">
                  <ul className="products-details-image-slides">
                    {product.images.map((item) => (
                      <li>
                        <img src={item.mediaURL} alt="image" />
                      </li>
                    ))}
                  </ul>

                  <div className="slick-thumbs">
                    <ul style={{"list-style-type":"none"}}>
                      {product.images.map((item) => (
                        <li>
                          <img src={item.mediaURL} alt="image" />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="products-details-desc">
                <h3>{product.item}</h3>

                <div className="price">
                  <span className="new-price">N{product.price}</span>
                </div>

                <ul className="products-info">
                  <li>
                    <span>Vendor:</span> <a href="#">{product.manufacturer}</a>
                  </li>
                  <li>
                    <span>Availability:</span>{" "}
                    <a href="#">In stock ({product.quantity} items)</a>
                  </li>
                  <li>
                    <span>Product Code:</span>{" "}
                    <a href="#">{product.product_code}</a>
                  </li>
                  <li>
                    <span>Description:</span>{" "}
                    <a href="#">{product.description}</a>
                  </li>
                </ul>
                {product.size_designation_items?.length > 0 && (
                  <div className={clsx("products-size-wrapper")}>
                    <span>Size:  {size?.size}</span>
                    <div>

                      <div
                        className={clsx(
                          "z-10 dropdown-content bg-white divide-y absolute divide-gray-100 rounded-lg shadow w-5/6 hidden",
                          isOpen ? "block" : "hidden"
                        )}
                      >
                        <ul className="py-2 text-sm text-gray-700 ">
                          {product.size_designation_items &&
                            product.size_designation_items.map((item,index) => (
                              <li key={index} onClick={() => setSize(item)}>
                                <button
                                  type="button"
                                  className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  <div className="inline-flex items-center">
                                    {item?.size}
                                  </div>
                                </button>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <div className="products-add-to-cart">
                  <div className="input-counter">
                    <span
                      className="minus-btn"
                      onClick={() => {
                        if (qty > 1) setQty(qty - 1);
                      }}
                    >
                      <i className="bx bx-minus"></i>
                    </span>
                    <input type="text" value={qty} />
                    <span
                      className="plus-btn"
                      onClick={() => {
                        if (qty < 50) setQty(qty + 1);
                      }}
                    >
                      <i className="bx bx-plus"></i>
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={addToCart}
                    className="default-btn"
                  >
                    <i className="fas fa-cart-plus"></i> Add to Cart
                  </button>
                </div>

                <div
                  style={{ display: "none" }}
                  className="wishlist-compare-btn"
                >
                  <a href="#" className="optional-btn">
                    <i className="bx bx-heart"></i> Add to Wishlist
                  </a>
                  <a href="#" className="optional-btn">
                    <i className="bx bx-refresh"></i> Add to Compare
                  </a>
                </div>

                <div className="buy-checkbox-btn">
                  <div className="item">
                    <input className="inp-cbx" id="cbx" type="checkbox" />
                    <label className="cbx" for="cbx">
                      <span>
                        <svg width="12px" height="10px" viewbox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>I agree with the terms and conditions</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProductDetails;
