export default
{
    // `method` is the request method to be used when making the request
    method: 'get', // default
    baseURL: 'https://foodloloapi.azurewebsites.net/api',
    timeout: 0, // default is `0` (no timeout)

    responseType: 'json', // default
  
    // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
  
    // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
    xsrfCookieName: 'XSRF-TOKEN', // default
  
    // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
    xsrfHeaderName: 'X-XSRF-TOKEN', // default
  
    // `onUploadProgress` allows handling of progress events for uploads
    // browser only
    onUploadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },
  
    // `onDownloadProgress` allows handling of progress events for downloads
    // browser only
    onDownloadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },
    headers: {
      Authorization : `Bearer ${localStorage.getItem("token")}`
    },
    maxContentLength: 2000,
  
    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    maxBodyLength: 2000,
    // proxy: {
    //   protocol: 'http',
    //   host: 'https://foodloloapi.azurewebsites.net',
    //   port: 1337
    // }
  };