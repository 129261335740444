import { Link } from 'react-router-dom';
import { appendScript } from '../../utils/appendScript'
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useCart } from "../../context/cart/cart.context";
import currency from '../../utils/currency.format';

const SideBarModal = () => {
    const { carts, total, removeCart } = useCart();

    useEffect(() => {
        appendScript("assets/js/main.js");

    }, []);

    const removeItem = (itemId) => {
        removeCart(itemId);
    }
    return (
        <>
            <div className="modal right fade sidebarModal" id="sidebarModal" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className='bx bx-x'></i></span>
                        </button>

                        <div className="modal-body">
                            <div className="sidebar-about-content">
                                <h3>About The Store</h3>

                                <div className="about-the-store">
                                    <p>One of the most popular on the web is shopping. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                                    <ul className="sidebar-contact-info">
                                        <li><i className='bx bx-map'></i> <a href="#" target="_blank">Wonder Street, USA, New York</a></li>
                                        <li><i className='bx bx-phone-call'></i> <a href="tel:+01321654214">+01 321 654 214</a></li>
                                        <li><i className='bx bx-envelope'></i> <a href="mailto:hello@xton.com">hello@xton.com</a></li>
                                    </ul>
                                </div>

                                <ul className="social-link">
                                    <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-linkedin'></i></a></li>
                                    <li><a href="#" className="d-block" target="_blank"><i className='bx bxl-pinterest-alt'></i></a></li>
                                </ul>
                            </div>

                            <div className="sidebar-new-in-store">
                                <h3>New In Store</h3>

                                <ul className="products-list">
                                    <li>
                                        <a href="#"><img src="assets/img/products/img1.jpg" alt="image" /></a>
                                    </li>

                                    <li>
                                        <a href="#"><img src="assets/img/products/img2.jpg" alt="image" /></a>
                                    </li>

                                    <li>
                                        <a href="#"><img src="assets/img/products/img3.jpg" alt="image" /></a>
                                    </li>

                                    <li>
                                        <a href="#"><img src="assets/img/products/img4.jpg" alt="image" /></a>
                                    </li>
                                </ul>

                                <Link to="/shops" className="shop-now-btn">Shop Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal right fade shoppingCartModal" id="shoppingCartModal" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className='bx bx-x'></i></span>
                        </button>

                        <div className="modal-body">
                            <h3>My Cart ({carts.length})</h3>

                            <div className="products-cart-content">
                                {carts.map((item, index) =>
                                    <div key={index} className="products-cart">
                                        <div className="products-image">
                                            <a href="#"><img src={item.image} alt="image" /></a>
                                        </div>

                                        <div className="products-content">
                                            <h3><a href="#">{item.name}</a></h3>
                                            <span><strong>Size:</strong> {item.size?.size}</span>
                                            <div className="products-price">
                                                <span>{item.qty}</span>
                                                <span>&nbsp; @&nbsp; </span>
                                                <span className="price">{currency(item.price)}</span>
                                                <span>&nbsp; = &nbsp; </span>
                                                <span className="price">{currency(item.price * item.qty)}</span>
                                            </div>
                                            <a href="#" onClick={() => removeItem(item.id)} className="remove-btn"><i className='bx bx-trash'></i></a>
                                        </div>
                                    </div>
                                )}


                            </div>

                            <div className="products-cart-subtotal">
                                <span>Subtotal</span>
                                <span className="subtotal">{currency(total)}</span>
                            </div>
                            <div className="products-cart-btn">
                                {/* <Link to="/checkout" className="default-btn">Proceed to Checkout</Link> */}
                                <Link to="/cart" className="optional-btn">View Shopping Cart</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SideBarModal;