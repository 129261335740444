import React from 'react';
import SiteMap from '.././components/header/site-map';

const About = () => {
    return (
        <>
            <SiteMap name={"About Cobbmed"} pageName={"About Us"} />
            <section className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <span className="sub-title">About Us</span>
                                <p>Formerly Saratoga NIG LTD., cobbMED Healthcare Solutions is a family owned and managed company, established in Nigeria in 2006. During a medical tourism visit to India, to repair a fractured tibia in 2004, a Saratoga Executive had a chance encounter with Mr. Maulin Gandhi, a third generation VISSCO family business owner.  Coincidentally, the Saratoga Executive was in the rehabilitation phase of his of his treatment, and had been prescribed VISSCO products, which had performed remarkably and outstandingly well, to that point.
Yes!!! cobbMED (Saratoga) was born at that point; and was born with a purpose: “To Empower People and their Loved Ones, To feel Less Pain.
True to its mission, and within a short span of time, cobbMED today offers one of the largest range of trusted orthopedic appliances, fracture aids, and mobility solutions, all through Nigeria and its neighboring West African countries.
cobbMED/VISSCO’s product portfolio addresses the entire continuum of patient care during the rehabilitation process.  </p>
<p>
We help prevent injuries (ergonomic office supports), provide support and pain relief during injuries, as well as offer products that enable mobility, while convalescing.
Our products provide solutions for musculoskeletal health, vascular health, as well as pain management.  We offer aids for various indications, including those surrounding the neck (cervical), Abdominal, Back(Lumber), Wrist, Elbow, Calf, Knee, Ankle, and Eyes.  So, whetehr acute, short-term, degenerative, chronic, regular, or sports related, we offer standardized and devised pain management solutions, all under one roof.
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default About;