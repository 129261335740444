import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from "../../context/cart/cart.context";
import { useAuth } from '../../context/user/user.context';
const Header = () => {
    const { count } = useCart();
    const { user, logout } = useAuth();
    return (
        <>
            <div className="top-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <ul className="header-contact-info">
                                <li>Welcome to Cobbmed</li>
                                <li>Call: <a href="tel:+2347045976541">+234 (0) 704 597 6541</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <ul className="header-top-menu">

                                {user ?
                                    <>
                                        <li>
                                            <a href='#'>Welcome {user.displayName}</a>
                                        </li>
                                        <li><Link to="/account"><i className='bx bxs-user'></i> My Account</Link></li>
                                        <li><a href='javascript:;' onClick={logout}><i className='bx bx-log-in'></i> Logout</a></li></>
                                    :
                                    <>
                                        <li><Link to="/login?redirectTo=account"><i className='bx bxs-user'></i> My Account</Link></li>
                                        <li><Link to="/login"><i className='bx bx-log-in'></i> Login</Link></li></>
                                }

                            </ul>

                            <ul className="header-top-others-option">
                                <div className="option-item">
                                    <div className="search-btn-box">
                                        <i className="search-btn bx bx-search-alt"></i>
                                    </div>
                                </div>

                                <div className="option-item">
                                    <div className="cart-btn">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#shoppingCartModal"><i className='bx bx-shopping-bag'></i><span>{count}</span></a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Header