export default function(){
    return (<section className="facility-area pt-100 pb-70">
    <div className="container">
        <div className="facility-slides owl-carousel owl-theme">
            {/* <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-tracking'></i>
                </div>
                <h3>Free Shipping Worldwide</h3>
            </div> */}

            <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-return'></i>
                </div>
                <h3>Easy Return Policy</h3>
            </div>

            {/* <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-shuffle'></i>
                </div>
                <h3>7 Day Exchange Policy</h3>
            </div> */}

            <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-sale'></i>
                </div>
                <h3>Weekend Discount Coupon</h3>
            </div>

            <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-credit-card'></i>
                </div>
                <h3>Secure Payment Methods</h3>
            </div>

            <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-location'></i>
                </div>
                <h3>Track Your Package</h3>
            </div>

            <div className="single-facility-box">
                <div className="icon">
                    <i className='flaticon-customer-service'></i>
                </div>
                <h3>24/7 Customer Support</h3>
            </div>
        </div>
    </div>
</section>)
}