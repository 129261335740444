import React from 'react';
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../context/user/user.context'
import SiteMap from '.././components/header/site-map';

const SignUp = () => {

    const { signUp } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [form, setForm] = useState({
        displayName: "",
        email: "",
        password: ""
    });

    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        await signUp(form).then((e)=>{console.log(e)
            navigate("/")
        }).catch(e=>{
            
        });
    };

    return (
        <>
            <SiteMap name={"My Account"} pageName={"Signup"} />
            <section className="signup-area ptb-100">
                <div className="container">
                    <div className="signup-content">
                        <h2>Create an Account</h2>

                        <form className="signup-form">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Country <span className="required">*</span></label>

                                        <div className="select-box">
                                            <select name='country' required value={form.country}
                                                onChange={onFormInputChange}
                                                className="form-control">
                                                <option>Nigeria</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Full Name  <span className="required">*</span></label>
                                        <input type="text" name='displayName' required value={form.displayName}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <label>Address <span className="required">*</span></label>
                                        <input type="text" name='address' value={form.address}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Town / City <span className="required">*</span></label>
                                        <input type="text" name='city' required value={form.city}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>State <span className="required">*</span></label>
                                        <input type="text" name='state' required value={form.state}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Email Address <span className="required">*</span></label>
                                        <input type="email" name='email' required value={form.email}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Phone <span className="required">*</span></label>
                                        <input type="text" name='phoneno' required value={form.phoneno}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Password <span className="required">*</span></label>
                                        <input type="password" name='password' required value={form.password}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Password <span className="required">*</span></label>
                                        <input type="password" name='copassword' required value={form.copassword}
                                            onChange={onFormInputChange} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <button onClick={onSubmit} type="submit" className="default-btn">Signup</button>

                            <Link to="/shops" className="return-store">or Return to Store</Link>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )

}
export default SignUp;