
var carts = localStorage.getItem("items");
if (!carts) {
    carts = [];
}
else {
    carts = JSON.parse(carts)
}

export const reducer = (state, action) => {
    switch (action.type) {
        case "GET_CART":
            return {
                ...state,
                carts: carts
            }
        case "SET_CART":
            localStorage.setItem("items", JSON.stringify(action.carts));
            return {
                ...state,
                carts: action.carts
            }

        default:
            return {
                ...state,
                carts: carts
            }
    }
}

export const initialState  = {
    carts: carts
}