import React, { useEffect, useState } from 'react';
import { appendScript } from '../../utils/appendScript'

import { $http } from '../../instance/axios.instance';
import { Config } from '../../config';

import { useAuth } from "../../context/user/user.context";
import Grid from '../../components/loader/grid';


const Product = React.lazy(() => import('../../components/product/product'));
const Slider = React.lazy(() => import('../../components/slider/slider'));

const HomePage = () => {

    const params = { skip: 0, limit: 15, page: 'homepage' }
    const [items, setItems] = useState([])
    const [sliders, setSliders] = useState([])
    const [loading, setLoading] = useState(false);
    const { authenticate } = useAuth();

    useEffect(() => {

        async function fetchData() {
            await authenticate();
            try {
                setLoading(true)
                const response = await $http.get(`/section/${Config.owner_id}/${params.skip}/${params.limit}?page=${params.page}&volume=8&loadlist=true`);
                const slide = response.data.filter((item) => { return item.use_carousel_view === true })
                if (slide.length > 0) {
                    setSliders(slide[0].image_items)
                }
                setItems(response.data.filter((item) => { return item.use_carousel_view === false }));
                setLoading(false)
                appendScript("assets/js/main.js");
                document.title = `Cobbmed`;
            }
            catch (e) {
                console.log(e)
                setLoading(false)
            }
        };
        fetchData();
    }, [true]);

    return (
        <div style={{textAlign: 'center'}}>
            {loading ? <Grid /> :
                <>
                    <Slider sliders={sliders} />
                    <Product products={items} />
                </>
            }
        </div>
    );
}
export default HomePage