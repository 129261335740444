import React, { useEffect } from 'react';
import { appendScript } from '../../utils/appendScript'
import SiteMap from '../../components/header/site-map';

const Product = React.lazy(() => import('../../components/product/products-without-sidebar'));

function ProductPage() {
    return (
        <>
            <SiteMap name={"Cobbmed Store"} pageName={"Shop"} />
            <Product />
        </>
    );
    useEffect(() => {
        document.title = "Cobbmed : Products";
        appendScript("assets/js/main.js");
    });
}
export default ProductPage;