import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appendScript } from '.././utils/appendScript'
import SiteMap from '.././components/header/site-map';
import { useCart } from "../context/cart/cart.context";
import currency from '../utils/currency.format';
import { usePaystackPayment } from 'react-paystack';
import { useAuth } from '../context/user/user.context';
import { Config } from '../config';
import { toast } from 'react-toastify';

const Checkout = () => {
    const { carts, total, ProcessOrder, genReference } = useCart();
    const { signUp, user } = useAuth();
    const [note, setNote] = useState("");
    const [checkoutText, setCheckoutText] = useState("Place Order");
    const [form, setForm] = useState({
        displayName: "",
        email: "",
        password: "",
        phoneno: "",
        address: "",
        city: "",
        state: "",
        note: "",
        confirmPassword: ""
    });

    const config = {
        reference: genReference(),
        email: user?.email,
        amount: (total+ Config.delivery_fee) * 100,
        publicKey: Config.key,
    };

    const initializePayment = usePaystackPayment(config);

    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {

        if (!user) {

            if (form.email === "" || form.password === "" || form.phoneno === "" || form.displayName === "" || form.city === "" || form.state === "") {
                toast.error('All fields mark * are required', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }

            setCheckoutText("...please wait");

            signUp(form).then(success => {
                if (success) {
                    initializePayment(onSuccess, onClose)
                }
            })
                .catch(e => {
                    setCheckoutText("Place Order");
                })
        }
        else {
            setCheckoutText("...please wait");
            initializePayment(onSuccess, onClose)
        }
    }

    const onSuccess = (res) => {
        ProcessOrder(config.reference, res.reference, note, user);
    };

    const onClose = () => {
        setCheckoutText("Place Order");
        console.log('closed')
    }

    useEffect(() => {
        document.title = "Cobbmed : Checkout";
        appendScript("assets/js/main.js");
    }, []);
    return (
        <>
            <SiteMap name={"Checkout"} productName={"Checkout"} />
            <section className="checkout-area ptb-100">
                <div className="container">
                    {!user &&
                        <div className="user-actions">
                            <i className='bx bx-log-in'></i>
                            <span>Returning customer? <Link to="/login?redirectTo=checkout">Click here to login</Link></span>
                        </div>
                    }

                    <form>
                        <div className="row">
                            {!user &&
                                <div className="col-lg-6 col-md-12">
                                    <div className="billing-details">
                                        <h3 className="title">Delivery Details</h3>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Country <span className="required">*</span></label>

                                                    <div className="select-box">
                                                        <select name='country' required value={form.country}
                                                            onChange={onFormInputChange}
                                                            className="form-control">
                                                            <option>Nigeria</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Full Name  <span className="required">*</span></label>
                                                    <input type="text" name='displayName' required value={form.displayName}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Address <span className="required">*</span></label>
                                                    <input type="text" name='address' value={form.address}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Town / City <span className="required">*</span></label>
                                                    <input type="text" name='city' required value={form.city}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>State <span className="required">*</span></label>
                                                    <input type="text" name='state' required value={form.state}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Email Address <span className="required">*</span></label>
                                                    <input type="email" name='email' required value={form.email}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Phone <span className="required">*</span></label>
                                                    <input type="text" name='phoneno' required value={form.phoneno}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Password <span className="required">*</span></label>
                                                    <input type="password" name='password' required value={form.password}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Confirm Password <span className="required">*</span></label>
                                                    <input type="password" name='copassword' required value={form.copassword}
                                                        onChange={onFormInputChange} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={`col-lg-${!user ? '6' : '12'} col-md-12`}>
                                <div className="order-details">
                                    <h3 className="title">Your Order</h3>

                                    <div className="order-table table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {carts.map((item) => (

                                                    <tr key={item.id}>
                                                        <td className="product-name">
                                                            <a href="#">{item.name}</a>
                                                        </td>
                                                        <td className="product-name">
                                                            <a href="#">{item.qty}</a>
                                                        </td>
                                                        <td className="product-total">
                                                            <span className="subtotal-amount">{currency(item.price * item.qty)}</span>
                                                        </td>
                                                    </tr>

                                                ))}

                                                {carts.length > 0 &&
                                                    <tr>
                                                        <td colSpan={2} className="order-shipping">
                                                            <span>Shipping</span>
                                                        </td>

                                                        <td className="shipping-price">
                                                            <span>{currency(Config.delivery_fee)}</span>
                                                        </td>
                                                    </tr>
                                                }

                                                <tr>
                                                    <td colSpan={2} className="total-price">
                                                        <span>Order Total</span>
                                                    </td>

                                                    <td className="product-subtotal">
                                                        <span className="subtotal-amount">{currency(total + Config.delivery_fee)}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="notes" value={note} onInput={(e) => setNote(e.value)}
                                                    id="notes" cols="30" rows="5" placeholder="Order Notes" className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="payment-box">
                                        <div className="payment-method">
                                            <p style={{ display: "none" }}>
                                                <input type="radio" id="direct-bank-transfer" name="radio-group" />
                                                <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                                Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
                                            </p>
                                            <p>
                                                <input type="radio" id="paypal" name="radio-group" checked />
                                                <label for="online">Online</label>
                                            </p>
                                        </div>
                                        {carts.length > 0 ?
                                            <a href="#" className="default-btn" onClick={onSubmit}>{checkoutText}</a>
                                            :
                                            <Link className="default-btn" to="/shops"> START SHOPPING</Link>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
export default Checkout;