import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appendScript } from '.././utils/appendScript'
import SiteMap from '.././components/header/site-map';
import { useCart } from "../context/cart/cart.context";
import currency from '../utils/currency.format';
import { Config } from '../config';

function Cart() {
    const { carts, total, removeCart, updateCart } = useCart();
console.log(carts)
    useEffect(() => {
        document.title = "Cobbmed : Cart";
        appendScript("assets/js/main.js");
    }, []);

    const removeItem = (itemId) => {
        removeCart(itemId);
    }

    return (
        <>
            <SiteMap name={"Cart"} productName={"Cart"} />
            <section className="cart-area ptb-100">
                <div className="container">
                    <form>
                        <div className="cart-table table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Unit Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {carts.map(item =>
                                        <tr key={item.id}>
                                            <td className="product-thumbnail">
                                                <a href="#">
                                                    <img src={item.image} alt="item" />
                                                </a>
                                            </td>

                                            <td className="product-name">
                                                <a href="#">{item.name}</a>
                                                <ul>
                                                    <li>Size: <span>{item.size?.size}</span></li>
                                                </ul>
                                            </td>

                                            <td className="product-price">
                                                <span className="unit-amount">{currency(item.price)}</span>
                                            </td>

                                            <td className="product-quantity">
                                                <div className="input-counter">
                                                    <span className="minus-btn" onClick={() => {
                                                        if (item.qty > 1) item.qty -= 1; updateCart(carts);
                                                    }}><i className='bx bx-minus'></i></span>
                                                    <input type="text" min="1" value={item.qty} max="50" />
                                                    <span className="plus-btn" onClick={() => {
                                                        if (item.qty < 50) item.qty += 1; updateCart(carts)
                                                    }}><i className='bx bx-plus'></i></span>
                                                </div>
                                            </td>

                                            <td className="product-subtotal">
                                                <span className="subtotal-amount">{currency(item.price * item.qty)}</span>

                                                <a href="#" onClick={() => removeItem(item.id)} className="remove"><i className='bx bx-trash'></i></a>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="cart-buttons">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-sm-7 col-md-7">
                                    <Link to="/shops" className="optional-btn">Continue Shopping</Link>
                                </div>

                                <div className="col-lg-5 col-sm-5 col-md-5 text-end">
                                    <a href="#" style={{ display: "none" }} className="default-btn">Update Cart</a>
                                </div>
                            </div>
                        </div>
                        {carts.length > 0 &&
                            <div className="cart-totals">
                                <h3>Cart Totals</h3>

                                <ul>
                                    <li>Subtotal <span>{currency(total)}</span></li>
                                    <li>Shipping <span>{currency(Config.delivery_fee)}</span></li>
                                    <li>Total <span>{currency(total + Config.delivery_fee)}</span></li>
                                </ul>

                                <Link to="/checkout" className="default-btn">Proceed to Checkout</Link>
                            </div>
                        }
                    </form>
                </div>
            </section>
        </>
    )
}
export default Cart;