import React, { createContext, useContext, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { $http } from "../../instance/axios.instance";
import { reducer, initialState } from "../userReducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Config } from "../../config";

export const UserContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();
  const [user, setUser] = useState(null);
  let [searchParams] = useSearchParams();

  const login = async ({ email, password, isAnonymous }) => {
    const res = await $http.post("/Login", {
      email: email,
      password: password,
      isAnonymous: isAnonymous,
      ownerId: Config.owner_id,
    });

    if (!res.data.successful) {
      toast.error("Incorrect username or password", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      throw new Error(res.data.error);
    }

    setCookies("token", res.data.token);

    localStorage.setItem("token", res.data.token);
    if (!isAnonymous && res.data.userId) {
      fetchUser(res.data.userId);
    } else {
      document.location.reload();
    }
  };

  const redirectNow = () => {
    let page = searchParams.get("redirectTo");
    page ? navigate(page) : navigate("/account");
  };

  const signUp = async (form) => {
    form.owner_id = Config.owner_id;
    form.role = "customer";
    await $http.post("/Accounts", form).then((res) => {
      if (res.status === 200) {
        if (res.data.successful) {
          const data = res.data.profile;
          dispatch({ type: "SET_USER", user: res.data });
          login({
            email: data.email,
            password: data.password,
            isAnonymous: false,
          });
          return true;
        } else {
          toast.error(res.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          throw new Error(res.data.error);
        }
      }
    });

    throw new Error("Operation failed! Please try again");
  };

  const fetchUser = async (userId) => {
    await $http
      .get(`/Accounts/${userId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
          dispatch({ type: "SET_USER", user: res.data });
        }
      });
  };

  const authenticate = async () => {
    if (!cookies.token) {
      await login({
        email: "test@test.com",
        password: "test1234",
        isAnonymous: true,
      });
    } else {
      //let decodedToken = jwt_decode(token);
      const expiry = JSON.parse(atob(cookies.token.split(".")[1])).exp;
      if (Math.floor(new Date().getTime() / 1000) >= expiry) {
        await login({
          email: "test@test.com",
          password: "test1234",
          isAnonymous: true,
        });
      }
    }
  };

  const logout = () => {
    removeCookie("token");
    localStorage.removeItem("token");
    setUser(null);
    navigate("/login");
  };

  const token = cookies.token;

  const value = {
    cookies,
    login,
    signUp,
    authenticate,
    logout,
    token,
    user,
    redirectNow,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
