import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../context/cart/cart.context";

export default function() {
  const { count } = useCart();
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-light">
        <Link className="navbar-brand" to="/">
          <img src="assets/img/logo.png" alt="logo" />
        </Link>

        <div className="collapse navbar-collapse mean-menu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                {" "}
                Home{" "}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/shops" className="nav-link">
                Shop
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>

          <div className="others-option">
            <div className="option-item">
              <div className="cart-btn">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#shoppingCartModal"
                >
                  <i className="bx bx-shopping-bag"></i>
                  <span>{count}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
