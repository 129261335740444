import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appendScript } from '.././utils/appendScript'
import SiteMap from '.././components/header/site-map';
import { useCart } from "../context/cart/cart.context";
import currency from '../utils/currency.format';
import { Config } from '../config';

function Success() {
    const { carts, total, clearCart } = useCart();
    const { orders } = useState(carts)
    console.log(orders)
    clearCart();
    useEffect(() => {
        document.title = "Cobbmed : Success";
        appendScript("assets/js/main.js");
    }, []);

    return (
        <>
            <SiteMap name={"Success"} productName={"Thank you"} />
            <section className="cart-area ptb-100">

                <div className="container">
                    <h2>Thank you for placing your order.</h2>
                    <form>
                        <div className="cart-table table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Unit Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        orders &&
                                        orders.map(item =>
                                            <tr key={item.id}>
                                                <td className="product-thumbnail">
                                                    <a href="#">
                                                        <img src={item.image} alt="item" />
                                                    </a>
                                                </td>

                                                <td className="product-name">
                                                    <a href="#">{item.name}</a>
                                                    <ul>
                                                        <li>Size: <span>{item.size}</span></li>
                                                    </ul>
                                                </td>

                                                <td className="product-price">
                                                    <span className="unit-amount">{currency(item.price)}</span>
                                                </td>

                                                <td className="product-quantity">
                                                    {item.qty}
                                                </td>

                                                <td className="product-subtotal">
                                                    <span className="subtotal-amount">{currency(item.price * item.qty)}</span>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>

                        <div className="cart-totals">
                            <h3>Order Totals</h3>

                            <ul>
                                <li>Subtotal <span>{currency(total)}.00</span></li>
                                <li>Shipping <span>{currency(Config.delivery_fee)}</span></li>
                                <li>Total <span>{currency(total + Config.delivery_fee)}</span></li>
                            </ul>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
export default Success;