import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import SiteMap from '.././components/header/site-map';
import { useAuth } from '../context/user/user.context'

const Login = () => {
    const { login,redirectNow } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [form, setForm] = useState({
        email: "",
        password: "",
        isAnonymous: false
    });

    const [loginText, setLoginText] = useState("Login");
    const [loading, setLoading] = useState(false)
    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {
        if(loading)
        {
            return;
        }

        event.preventDefault();
        setLoading(true)
        setLoginText("...please wait")
        await login(form).then(e=> redirectNow()).catch((e)=>{
            setLoginText("Login")
            setLoading(false)
        });
    }

    return (
        <>
            <SiteMap name={"My Account"} pageName={"Login"} />
            <section className="login-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="login-content">
                                <h2>Login</h2>

                                <form method='post' className="login-form">
                                    <div className="form-group">
                                        <input type="email" 
                                        value={form.email}
                                        name="email"
                                        onChange={onFormInputChange} 
                                        className="form-control" placeholder="Username or email address" />
                                    </div>

                                    <div className="form-group">
                                        <input type="password" 
                                        value={form.password}
                                        name="password"
                                        onChange={onFormInputChange}
                                        className="form-control" placeholder="Password" />
                                    </div>

                                    <button onClick={ onSubmit } type="submit" className="default-btn">{loginText}</button>

                                    <a href="#" className="forgot-password">Lost your password?</a>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="new-customer-content">
                                <h2>New Customer</h2>

                                <span>Create A Account</span>
                                <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                                <Link to="/signup" className="optional-btn">Create A Account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default Login;