import axiosConfig from "../axios.config";
import { useAuth } from "../context/user/user.context";
const axios = require('axios');
const $http = axios.create(axiosConfig);
$http.interceptors.response.use(function (response) {
    //Auth();
    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        //Auth()
    }
    return Promise.reject(error);
},
    config => {
        const tokenVal = localStorage.getItem("token");
        if (tokenVal) {
            config.headers['Authorization'] = `Bearer ${tokenVal}`;
        }
        return config;
    },
);

const Auth = async ()=>{
    const { authenticate } = useAuth();
    await authenticate();
}
if(!axiosConfig.headers.Authorization)
{
    Auth();
}

axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
export { $http };