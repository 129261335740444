import React from "react"
import ContentLoader from "react-content-loader"

const Grid = (props) => (
  <ContentLoader 
    speed={2}
    width={1500}
    height={800}
    viewBox="0 0 1500 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" /> 
    <rect x="-14" y="108" rx="0" ry="0" width="611" height="250" /> 
    <rect x="3" y="374" rx="0" ry="0" width="196" height="165" /> 
    <rect x="241" y="453" rx="0" ry="0" width="0" height="1" /> 
    <rect x="206" y="375" rx="0" ry="0" width="196" height="165" /> 
    <rect x="409" y="377" rx="0" ry="0" width="196" height="165" /> 
    <rect x="2" y="551" rx="0" ry="0" width="196" height="165" /> 
    <rect x="204" y="552" rx="0" ry="0" width="196" height="165" /> 
    <rect x="408" y="553" rx="0" ry="0" width="196" height="165" />
  </ContentLoader>
)

export default Grid